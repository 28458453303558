<template>
	<div class="container">
		<div class="hero-area-slider">
			<owl-item  v-for="(movie, index) in movies" :key="index" :movie="movie"></owl-item>
		</div>
		<div class="hero-area-thumb" v-if="movies.length > 0">
			<owl-navigation :prevObj="firstMovie" :nextObj="lastMovie"></owl-navigation>
		</div>
	</div>
</template>
<script>
import owlItem from "./owlItem";
import owlNavigation from "./owlNavigation";
export default {
    components: {
        owlItem,
        owlNavigation
    },
	data() {
		return {
            movies: [],
			firstMovie: {},
			lastMovie: {}
        }
	},
    methods: {
        getData() {
			var _this = this;
			this.$api.get("/apiData/Movie/Get").then(response => {
				_this.movies = response.result.data;
				_this.firstMovie = _this.movies[2]
				_this.lastMovie = _this.movies[1];
			},
			err => {
				this.$store.getters.errorParse(this, err);
			})
		}
    },
	mounted() {
		this.getData();
	}
}
</script>