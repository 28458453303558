<template>
    <div class="row hero-area-slide">
		<div class="col-lg-6 col-md-5">
			<div class="hero-area-content">
				<img :src="$store.state.fileUrl + `/moviesPhoto/` + movie.photo" alt="movie" />
			</div>
		</div>
		<div class="col-lg-6 col-md-7">
			<div class="hero-area-content pr-50">
				<h2>{{ movie.name }}</h2>
				<div class="review">
					<div class="author-review">
						<i class="icofont icofont-star"></i>
						<i class="icofont icofont-star"></i>
						<i class="icofont icofont-star"></i>
						<i class="icofont icofont-star"></i>
						<i class="icofont icofont-star"></i>
					</div>
					<h4>180k ovozlar</h4>
				</div>
				<p>{{ stringSubtr(movie.info) }}</p>
				<h3>Rollarda:</h3>
				<div class="slide-cast">
					<div class="single-slide-cast" v-for="(actor, key) in movie.actors" :key="key">
						<router-link :to="`/movie/actor/` + actor.id">
							<img :src="$store.state.fileUrl + `/actors/` + actor.photo" alt="actor" />
						</router-link>
					</div>
					<div class="single-slide-cast text-center">
						5+
					</div>
				</div>
				<div class="slide-trailor">
					<h3>Tomosho qilish</h3>
					<router-link :to="`/movie/details/` + movie.slug" class="theme-btn theme-btn2"><i class="icofont icofont-play"></i> Play</router-link>
				</div>
			</div>
		</div>
    </div>
</template>
<script>
export default {
    props: {
		movie: {
			type: Object
		}
	},
	data() {
		return {}
	},
	methods: {
		stringSubtr(text){
			var result = text.substring(0, 250) + "...";
			return result;
		}
	},
    mounted() {
		const plugin = document.createElement("script");
		plugin.setAttribute("src", "/assets/js/slider.js");
		plugin.async = true;
		document.head.appendChild(plugin);
	}
}
</script>