<template>
	<div>
		<div class="login-area">
			<div class="login-box">
				<a href="#"><i class="icofont icofont-close"></i></a>
				<h2>Tizimga kirish</h2>
				<form action="#">
					<h6>Login</h6>
					<input type="text" />
					<h6>Parol</h6>
					<input type="text" />
					<div class="login-remember">
						<input type="checkbox" />
						<span> Eslab qolish</span>
					</div>
					<div class="login-signup">
						<span>Ro‘yxatdan o‘tish</span>
					</div>
					<a href="#" class="theme-btn">Kirish</a>
					<!-- <span>Or Via Social</span>
					<div class="login-social">
						<a href="#"><i class="icofont icofont-social-facebook"></i></a>
						<a href="#"><i class="icofont icofont-social-twitter"></i></a>
						<a href="#"><i class="icofont icofont-social-linkedin"></i></a>
						<a href="#"><i class="icofont icofont-social-google-plus"></i></a>
						<a href="#"><i class="icofont icofont-camera"></i></a>
					</div> -->
				</form>

			</div>
		</div>
		<div class="buy-ticket">
			<div class="container">
				<div class="buy-ticket-area">
					<a href="#"><i class="icofont icofont-close"></i></a>
					<div class="row">
						<div class="col-lg-8">
							<div class="buy-ticket-box">
								<h4>Buy Tickets</h4>
								<h5>Seat</h5>
								<h6>Screen</h6>
								<div class="ticket-box-table">
									<table class="ticket-table-seat">
										<tr>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
										</tr>
										<tr>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
										</tr>
										<tr>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
										</tr>
										<tr>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
										</tr>
										<tr>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
										</tr>
									</table>
									<table>
										<tr>
											<td>1</td>
										</tr>
										<tr>
											<td>2</td>
										</tr>
										<tr>
											<td>3</td>
										</tr>
										<tr>
											<td>4</td>
										</tr>
										<tr>
											<td>5</td>
										</tr>
									</table>
									<table class="ticket-table-seat">
										<tr>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
										</tr>
										<tr>
											<td class="active">1</td>
											<td class="active">1</td>
											<td class="active">1</td>
											<td class="active">1</td>
											<td class="active">1</td>
											<td class="active">1</td>
											<td class="active">1</td>
										</tr>
										<tr>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
										</tr>
										<tr>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
										</tr>
										<tr>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
										</tr>
									</table>
									<table>
										<tr>
											<td>1</td>
										</tr>
										<tr>
											<td>2</td>
										</tr>
										<tr>
											<td>3</td>
										</tr>
										<tr>
											<td>4</td>
										</tr>
										<tr>
											<td>5</td>
										</tr>
									</table>
									<table class="ticket-table-seat">
										<tr>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
										</tr>
										<tr>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
										</tr>
										<tr>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
										</tr>
										<tr>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
										</tr>
										<tr>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
											<td>1</td>
										</tr>
									</table>
								</div>
								<div class="ticket-box-available">
									<input type="checkbox" />
									<span>Available</span>
									<input type="checkbox" checked />
									<span>Unavailable</span>
									<input type="checkbox" />
									<span>Selected</span>
								</div>
								<a href="#" class="theme-btn">previous</a>
								<a href="#" class="theme-btn">Next</a>
							</div>
						</div>
						<div class="col-lg-3 offset-lg-1">
							<div class="buy-ticket-box mtr-30">
								<h4>Your Information</h4>
								<ul>
									<li>
										<p>Location</p>
										<span>HB Cinema Box Corner</span>
									</li>
									<li>
										<p>TIME</p>
										<span>2018.07.09 20:40</span>
									</li>
									<li>
										<p>Movie name</p>
										<span>Home Alone</span>
									</li>
									<li>
										<p>Ticket number</p>
										<span>2 Adults, 2 Children, 2 Seniors</span>
									</li>
									<li>
										<p>Price</p>
										<span>89$</span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div><!-- header section end -->
		<!-- hero area start -->
		<section class="hero-area" id="home">
			<owl-wrapper></owl-wrapper>
		</section><!-- hero area end -->
		<!-- portfolio section start -->
		<!-- <section class="portfolio-area pt-60">
			<div class="container">
				<div class="row flexbox-center">
					<div class="col-lg-6 text-center text-lg-left">
						<div class="section-title">
							<h1><i class="icofont icofont-movie"></i> Tarjima qilinayotganlari</h1>
						</div>
					</div>
					<div class="col-lg-6 text-center text-lg-right">
						<div class="portfolio-menu">
							<ul>
								<li data-filter="*" class="active">Kinolar</li>
								<li data-filter=".soon">Seriallar</li>
								<li data-filter=".top">Multfilmlar</li>
								<li data-filter=".released">Animelar</li>
							</ul>
						</div>
					</div>
				</div>
				<hr />
				<div class="row">
					<div class="col-lg-12">
						<div class="row portfolio-item">
							<div class="col-md-4 col-sm-6 soon released">
								<div class="single-portfolio">
									<div class="single-portfolio-img">
										<img src="assets/img/translation/29.jpg" alt="portfolio" />
										<a href="https://www.youtube.com/watch?v=RZXnugbhw_4" class="popup-youtube">
											<i class="icofont icofont-ui-play"></i>
										</a>
									</div>
									<div class="portfolio-content">
										<h2>Pulp Fiction (1994)</h2>
									</div>
								</div>
							</div>
							<div class="col-md-4 col-sm-6 top">
								<div class="single-portfolio">
									<div class="single-portfolio-img">
										<img src="assets/img/translation/27.jpg" alt="portfolio" />
										<a href="https://www.youtube.com/watch?v=RZXnugbhw_4" class="popup-youtube">
											<i class="icofont icofont-ui-play"></i>
										</a>
									</div>
									<div class="portfolio-content">
										<h2>Fight Club (1999)</h2>
									</div>
								</div>
							</div>
							<div class="col-md-4 col-sm-6 soon">
								<div class="single-portfolio">
									<div class="single-portfolio-img">
										<img src="assets/img/translation/30.jpg" alt="portfolio" />
										<a href="https://www.youtube.com/watch?v=RZXnugbhw_4" class="popup-youtube">
											<i class="icofont icofont-ui-play"></i>
										</a>
									</div>
									<div class="portfolio-content">
										<h2>Mortal Combat (1997)</h2>
									</div>
								</div>
							</div>
							<div class="col-md-4 col-sm-6 top released">
								<div class="single-portfolio">
									<div class="single-portfolio-img">
										<img src="assets/img/translation/33.jpg" alt="portfolio" />
										<a href="https://www.youtube.com/watch?v=RZXnugbhw_4" class="popup-youtube">
											<i class="icofont icofont-ui-play"></i>
										</a>
									</div>
									<div class="portfolio-content">
										<h2>Bleyd (1998)</h2>
									</div>
								</div>
							</div>
							<div class="col-md-4 col-sm-6 released">
								<div class="single-portfolio">
									<div class="single-portfolio-img">
										<img src="assets/img/translation/34.jpg" alt="portfolio" />
										<a href="https://www.youtube.com/watch?v=RZXnugbhw_4" class="popup-youtube">
											<i class="icofont icofont-ui-play"></i>
										</a>
									</div>
									<div class="portfolio-content">
										<h2>Tropic Thunder (2008)</h2>
									</div>
								</div>
							</div>
							<div class="col-md-4 col-sm-6 soon top">
								<div class="single-portfolio">
									<div class="single-portfolio-img">
										<img src="assets/img/translation/35.jpg" alt="portfolio" />
										<a href="https://www.youtube.com/watch?v=RZXnugbhw_4" class="popup-youtube">
											<i class="icofont icofont-ui-play"></i>
										</a>
									</div>
									<div class="portfolio-content">
										<h2>Zodiac (2007)</h2>
									</div>
								</div>
							</div>
							<div class="col-md-4 col-sm-6 released">
								<div class="single-portfolio">
									<div class="single-portfolio-img">
										<img src="assets/img/translation/37.jpg" alt="portfolio" />
										<a href="https://www.youtube.com/watch?v=RZXnugbhw_4" class="popup-youtube">
											<i class="icofont icofont-ui-play"></i>
										</a>
									</div>
									<div class="portfolio-content">
										<h2>Goodfellas (1990)</h2>
									</div>
								</div>
							</div>
							<div class="col-md-4 col-sm-6 soon top">
								<div class="single-portfolio">
									<div class="single-portfolio-img">
										<img src="assets/img/translation/39.jpg" alt="portfolio" />
										<a href="https://www.youtube.com/watch?v=RZXnugbhw_4" class="popup-youtube">
											<i class="icofont icofont-ui-play"></i>
										</a>
									</div>
									<div class="portfolio-content">
										<h2>500 Days Of Summer (2009)</h2>
									</div>
								</div>
							</div>
							<div class="col-md-4 col-sm-6 soon top">
								<div class="single-portfolio">
									<div class="single-portfolio-img">
										<img src="assets/img/translation/40.jpg" alt="portfolio" />
										<a href="https://www.youtube.com/watch?v=RZXnugbhw_4" class="popup-youtube">
											<i class="icofont icofont-ui-play"></i>
										</a>
									</div>
									<div class="portfolio-content">
										<h2>Taxi Driver (1976)</h2>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section> -->
		<!-- portfolio section end -->
		<!-- video section start -->
		<!-- <section class="video ptb-90">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="section-title pb-20">
							<h1><i class="icofont icofont-film"></i> Treylerlar</h1>
						</div>
					</div>
				</div>
				<hr />
				<div class="row">
					<div class="col-md-9">
						<div class="video-area">
							<img src="assets/img/trailer/21.jpg" alt="video" />
							<a href="https://www.youtube.com/watch?v=U2Qp5pL3ovA" class="popup-youtube">
								<i class="icofont icofont-ui-play"></i>
							</a>
							<div class="video-text">
								<h2>Kunfu Panda 4</h2>
								<div class="review">
									<div class="author-review">
										<i class="icofont icofont-star"></i>
										<i class="icofont icofont-star"></i>
										<i class="icofont icofont-star"></i>
										<i class="icofont icofont-star"></i>
										<i class="icofont icofont-star"></i>
									</div>
									<h4>180k voters</h4>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-3">
						<div class="row">
							<div class="col-md-12 col-sm-6">
								<div class="video-area">
									<img src="assets/img/trailer/15.jpg" alt="video" />
									<a href="https://www.youtube.com/watch?v=_inKs4eeHiI" class="popup-youtube">
										<i class="icofont icofont-ui-play"></i>
									</a>
								</div>
							</div>
							<div class="col-md-12 col-sm-6">
								<div class="video-area">
									<img src="assets/img/trailer/24.jpg" alt="video" />
									<a href="https://www.youtube.com/watch?v=UGc5Tzz19UY" class="popup-youtube">
										<i class="icofont icofont-ui-play"></i>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section> -->
		<!-- video section end -->
		<!-- news section start -->
		<!-- <section class="news">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="section-title pb-20">
							<h1><i class="icofont icofont-newspaper"></i> Yangiliklar</h1>
						</div>
					</div>
				</div>
				<hr />
			</div>
			<div class="news-slide-area">
				<div class="news-slider">
					<div class="single-news">
						<div class="news-bg-1" style="background: url('assets/img/news/41.jpg') no-repeat center / cover;">
						</div>
						<div class="news-date">
							<h2><span>Yan</span> 28</h2>
							<h1>2024</h1>
						</div>
						<div class="news-content">
							<h2>MANDALORIYALIK VA GROGU</h2>
							<p>Xabarlarga ko‘ra "MANDALORIYALIK VA GROGU" filmining suratga olish ishlari 17-iyundan
								boshlanadi.
								Rey haqidagi "YULDUZLAR JANGI: YANGI JEDAY TARTIBI" filmining suratga olish ishlari may
								oyidan boshlanadi.</p>
						</div>
					</div>
					<div class="single-news">
						<div class="news-bg-2" style="background: url('assets/img/news/45.jpg') no-repeat center / cover;">
						</div>
						<div class="news-date">
							<h2><span>Yan</span> 29</h2>
							<h1>2024</h1>
						</div>
						<div class="news-content">
							<h2>MOMAQALDIROQLAR</h2>
							<p>Mish-mishlarga qaraganda Lourens Fishburn va Reychel Vays "MOMAQALDIROQLAR" filmida Bill
								Foster va Milena Vostokoff rollariga qaytishadi.</p>
						</div>
					</div>
					<div class="single-news">
						<div class="news-bg-3" style="background: url('assets/img/news/46.jpg') no-repeat center / cover;">
						</div>
						<div class="news-date">
							<h2><span>Yan</span> 31</h2>
							<h1>2024</h1>
						</div>
						<div class="news-content">
							<h2>Loki va Deadpul</h2>
							<p>"Ha men uchun Dedpul Loki haqida nima deyishini ko'rish juda qiziq. Menimcha u qurib ketar
								edi. Garchi Loki o‘tkir tili bilan mashhur bo'lsa ham menimcha u Dedpulga so‘zamonlikda bas
								kelish uchun borini beradi."</p>
						</div>
					</div>
				</div>
				<div class="news-thumb">
					<div class="news-next">
						<div class="single-news">
							<div class="news-bg-3"
								style="background: url('assets/img/news/46.jpg') no-repeat center / cover;"></div>
							<div class="news-date">
								<h2><span>NOV</span> 25</h2>
								<h1>2017</h1>
							</div>
							<div class="news-content">
								<h2>The Witch Queen</h2>
								<p>Witch Queen is a tall woman with a slim build. She has pink hair, which is pulled up
									under her hat, and teal eyes.</p>
							</div>
							<a href="#">Read More</a>
						</div>
					</div>
					<div class="news-prev">
						<div class="single-news">
							<div class="news-bg-2"
								style="background: url('assets/img/news/45.jpg') no-repeat center / cover;"></div>
							<div class="news-date">
								<h2><span>NOV</span> 25</h2>
								<h1>2017</h1>
							</div>
							<div class="news-content">
								<h2>The Witch Queen</h2>
								<p>Witch Queen is a tall woman with a slim build. She has pink hair, which is pulled up
									under her hat, and teal eyes.</p>
							</div>
							<a href="#">Read More</a>
						</div>
					</div>
				</div>
			</div>
		</section> -->
		<!-- news section end -->

	</div>
</template>

<script>
import owlWrapper from "./components/banner/owlWrapper";

export default {
	components: {
		owlWrapper
	},
	data() {
		return {};
	},
};
</script>