<template>
	<div class="hero-area-thumb">
		<div class="thumb-prev" @click="owlPrev()">
			<div class="row hero-area-slide">
				<div class="col-lg-6">
					<div class="hero-area-content">
						<img :src="$store.state.fileUrl + `/moviesPhoto/` + prevObj.photo" alt="movie" />
					</div>
				</div>
				<div class="col-lg-6">
					<div class="hero-area-content pr-50">
						<h2>{{ prevObj.name }}</h2>
						<div class="review">
							<h4>180k voters</h4>
						</div>
						<p>{{ prevObj.info }}</p>
						<h3>Rollarda:</h3>
						<div class="slide-cast">
							<div class="single-slide-cast" v-for="(actor, key) in prevObj.actors" :key="key">
								<img :src="$store.state.fileUrl + `/actors/` + actor.photo" alt="actor" />
							</div>
							<div class="single-slide-cast text-center">
								5+
							</div>
						</div>
						<div class="slide-trailor">
							<h3>Trailerni ko‘rish</h3>
							<a class="theme-btn theme-btn2" href="#"><i class="icofont icofont-play"></i> Play</a>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="thumb-next" @click="owlNext()">
			<div class="row hero-area-slide">
				<div class="col-lg-6">
					<div class="hero-area-content">
						<img :src="$store.state.fileUrl + `/moviesPhoto/` + nextObj.photo" alt="movie" />
					</div>
				</div>
				<div class="col-lg-6">
					<div class="hero-area-content pr-50">
						<h2>{{ nextObj.name }}</h2>
						<div class="review">
							<h4>180k voters</h4>
						</div>
						<p>{{ nextObj.info }}</p>
						<h3>Rollarda:</h3>
						<div class="slide-cast">
							<div class="single-slide-cast" v-for="(actor, key) in nextObj.actors" :key="key">
								<img :src="$store.state.fileUrl + `/actors/` + actor.photo" alt="actor" />
							</div>
							<div class="single-slide-cast text-center">
								5+
							</div>
						</div>
						<div class="slide-trailor">
							<h3>Trailerni ko‘rish</h3>
							<a class="theme-btn theme-btn2" href="#"><i class="icofont icofont-play"></i> Play</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		prevObj: {
			type: Object
		},
		nextObj: {
			type: Object
		}
	},
	data() {
		return {}
	},
	methods: {
		owlPrev() {
			heroSlider.trigger('prev.owl.carousel', [300]);
			return false;
		},
		owlNext() {
			heroSlider.trigger('next.owl.carousel', [300]);
			return false;
		}
	}
}
</script>